.App {
  text-align: center;
  overflow-x: hidden;
  color: #1A2B71 !important;
} 

.chirmanMsgPc{
  display: block;
}
.chirmanMsgMobile{
  display: none;
}
.homeSubmenuIcon{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.1);
}
.homeSubmenuIcon img{
  width: 30px;
}
.headerBottom{
  height: 165px;
}
.HomeSlider{
  height: 70vh;
}
.homeWindowSlider{
  display: block;
}
.homeMobileSlider{
  display: none ;
}
/* =========== */
.marquee-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.marquee {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 30s linear infinite;
}
.marquee:hover {
  animation-play-state: paused;
}
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee_new {
  animation: Lineanimate 0.3s linear infinite !important;
}

@keyframes Lineanimate {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* =========== */
.marquee_new{
  animation: Lineanimate .3s linear infinite !important;  
}
@keyframes Lineanimate{
  0%{
    opacity: 0.5;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
}
.chairmanMassage{
  background-image: linear-gradient(to right ,#192a6f,#142466,#0f1f5f,#081753,#03114b)
}
.chairmanMassageTitleMobie{
  display: none;
}
.chairmanMassageTitlePc{
  display: block;
  margin-top: 20px;
}
.chairmanMassageCover{
  display: none;
  width: 100%;
  height: 30%;
  background-image: linear-gradient( transparent , rgba(25,43,111,0.99));
  position: absolute;
  bottom: 0%;
  left: 0;
}
.maltipalWay{
  position: relative;
  width: 100%;
  height: 580px;
  border-radius: 0 20px 20px 20px;
  overflow: hidden;
  transition: 1s;
  border: none;
}
.maltipalWayText{
  position: absolute;
  background-color: white;
  top: 85%;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center; 
  color: black;
  transition: 1s;
}
.maltipalWayTextL{
  display: none;
}
.maltipalWay:hover .maltipalWayText{
  top: 15%;
  left: 0;
  text-align: start;
  transition: 1s; 
}
.maltipalWay:hover .maltipalWayTextL{
  display: block;
}
.rateLeft{
  background:#e9eaf1 ;
  border-radius: 60px 0 0 60px;
}
.rateRight{
  background:#e9eaf1 ; 
  border-radius:  0 60px  60px 0;

}
.rateIcon{
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: white;
  margin: 0 5px;
}
.rateIcon > img{
  width: 60px;
}
.rateTitle{
  font-size: 22px;
}
.rateNumber{
  font-size: 35px; 
}


/* button */
.btn-orang{
  background: #FD5900;
  color: white;
  border-radius: 0 20px 20px 0;
  font-weight: 600;
  border :1px solid #FD5900;
  padding: 5px 10px;
  transition: 0.5s;
}
.btn-orang:hover{
  background:transparent;
  color: #FD5900;
  border-radius: 0 20px 20px 0;
  font-weight: 600;
  border :1px solid #FD5900;
  padding: 5px 10px;
  transition: 0.5s; 
}

.btn-blue{
  background: #1A2B71;
  color: white;
  border-radius: 0 20px 20px 0;
  font-weight: 600;
  border :1px solid #1A2B71;
  padding: 5px 10px;
  transition: 0.5s;
}
.btn-blue:hover{
  background:transparent;
  color: #1A2B71;
  border-radius: 0 20px 20px 0;
  font-weight: 600;
  border :1px solid #1A2B71;
  padding: 5px 10px;
  transition: 0.5s; 
}

.btn-white{
  background: #FFFFFF;
  color: #1A2B71;
  border-radius: 0 20px 20px 0;
  font-weight: 600;
  border :1px solid #FFFFFF;
  padding: 5px 10px;
  transition: 0.5s;
}
.btn-white:hover{
  background:transparent;
  color: #FFFFFF;
  border-radius: 0 20px 20px 0;
  font-weight: 600;
  border :1px solid #FFFFFF;
  padding: 5px 10px;
  transition: 0.5s; 
}
.btn-green{
  background: #3A6200;
  color: white;
  border-radius: 0 20px 20px 0;
  font-weight: 600;
  border :1px solid #3A6200;
  padding: 5px 10px;
  transition: 0.5s;
}
.btn-green:hover{
  background:transparent;
  color: #3A6200;
  border-radius: 0 20px 20px 0;
  font-weight: 600;
  border :1px solid #3A6200;
  padding: 5px 10px;
  transition: 0.5s; 
}
.btn-black{
  background: black;
  color: white;
  border-radius: 0 20px 20px 0;
  font-weight: 600;
  border :1px solid black;
  padding: 5px 10px;
  transition: 0.5s;
}
.btn-black:hover{
  background:transparent;
  color: black;
  border-radius: 0 20px 20px 0;
  font-weight: 600;
  border :1px solid black;
  padding: 5px 10px;
  transition: 0.5s; 
}

.ts-1{
  font-size: 12px ;
}
.ts-2{
  font-size: 18px ;
}
.ts-3{
  font-size: 22px;
}
.ts-4{
  font-size: 28px;
}
.ts-5{
  font-size: 30px;
}
.ts-6{
  font-size: 40px;
}
.ts-7{
  font-size: 50px;
}
.logoText{
  display: block;
  line-height: 35px;
  font-weight: 900;
  /* letter-spacing : -2px */
  
   
   /* background-color:white; */
}

 
.pagination{ 
  --bs-pagination-color: #1A2B71;
}

.active>.page-link {
  background: #1A2B71;
  color: white;
  border: #1A2B71;
  z-index: 1;
}
@media(max-width:1450px){
  .ts-1{
    font-size: 12px ;
  }
  .ts-2{
    font-size: 16px ;
  }
  .ts-3{
    font-size: 18px;
  }
  .ts-4{
    font-size: 20px;
  }
  .ts-5{
    font-size: 25px;
  }
  .ts-6{
    font-size: 30px;
  }
  .ts-7{
    font-size: 40px;
  }
  .maltipalWay{
    height: 420px;
  }
  .maltipalWay:hover .maltipalWayText{
    top: 5%;
    left: 0;
    text-align: start;
    transition: 1s; 
  }
  .rateLeft{
    background:#e9eaf1 ;
    border-radius: 60px 0 0 60px;
  }
  .rateRight{
    background:#e9eaf1 ; 
    border-radius:  0 60px  60px 0;
  
  }
  .rateIcon{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: white;
    margin: 0 5px;
  }
  .rateIcon > img{
    width: 40px;
  }
  .rateTitle{
    font-size: 20px;
  }
  .rateNumber{
    font-size: 30px; 
  }
  
  
}
@media (max-width: 992px) {
  .ts-1{
    font-size: 12px ;
  }
  .ts-2{
    font-size: 16px ;
  }
  .ts-3{
    font-size: 18px;
  }
  .ts-4{
    font-size: 20px;
  }
  .ts-5{
    font-size: 25px;
  }
  .ts-6{
    font-size: 28px;
  }
  .ts-7{
    font-size: 35px;
  }
  .rateLeft{
    background:#e9eaf1 ;
    border-radius: 60px 0 0 60px;
  }
  .rateRight{
    background:#e9eaf1 ; 
    border-radius:  0 60px  60px 0;
  
  }
  .rateIcon{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: white;
    margin: 0 5px;
  }
  .rateIcon > img{
    width: 30px;
  }
  .headerBottom{
    height: 70px;
  }
  .homeWindowSlider{
    display: none;
  }
  .homeMobileSlider{
    display: block ;
  }
  .chairmanMassage{
    background-image: linear-gradient(#192a6f,#192a6f )
  }
  .chairmanMassageCover{
    display: block;
    width: 100%;
    height: 30%;
    background-image: linear-gradient( transparent , rgba(25,43,111,0.99));
    position: absolute;
    bottom: 0%;
    left: 0;
  }
  .chairmanMassageTitlePc{
    display: none;
  }
  .chairmanMassageTitleMobie{
    display: block;
    text-align: start;
    color: white;
    margin-top: 20px;
  }
}
 @media (max-width: 768px) { 
  .chirmanMsgPc{
    display: none;
  }
  .chirmanMsgMobile{
    display: block;
  }
  .homeSubmenuIcon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(0,0,0,0.1);
  }
  .homeSubmenuIcon img{
    width: 20px;
  }
  .logoText{
    display: none;
  }
  .ts-1{
    font-size: 12px ;
  }
  .ts-2{
    font-size: 13px ;
  }
  .ts-3{
    font-size: 16px;
  }
  .ts-4{
    font-size: 18px;
  }
  .ts-5{
    font-size: 15px;
  }
  .ts-6{
    font-size: 28px;
  }
  .ts-7{
    font-size: 30px;
  }
  .btn-orang{
    background: #FD5900;
    color: white;
    border-radius: 0 20px 20px 0;
    font-weight: 600;
    border :1px solid #FD5900;
    padding: 5px 10px;
    transition: 0.5s;
    font-size: 15px;
  }
  .btn-orang:hover{
    background:transparent;
    color: #FD5900;
    border-radius: 0 20px 20px 0;
    font-weight: 600;
    border :1px solid #FD5900;
    padding: 5px 10px;
    transition: 0.5s; 
    font-size: 15px;
  }
  
  .btn-blue{
    background: #1A2B71;
    color: white;
    border-radius: 0 20px 20px 0;
    font-weight: 600;
    border :1px solid #1A2B71;
    padding: 5px 10px;
    transition: 0.5s;
    font-size: 15px;
  }
  .btn-blue:hover{
    background:transparent;
    color: #1A2B71;
    border-radius: 0 20px 20px 0;
    font-weight: 600;
    border :1px solid #1A2B71;
    padding: 5px 10px;
    transition: 0.5s; 
    font-size: 15px;
  }
  
  .btn-white{
    background: #FFFFFF;
    color: black;
    border-radius: 0 20px 20px 0;
    font-weight: 600;
    border :1px solid #FFFFFF;
    padding: 5px 10px;
    transition: 0.5s;
    font-size: 15px;
  }
  .btn-white:hover{
    background:transparent;
    color: #FFFFFF;
    border-radius: 0 20px 20px 0;
    font-weight: 600;
    border :1px solid #FFFFFF;
    padding: 5px 10px;
    transition: 0.5s;
    font-size: 15px; 
  }
  .btn-green{
    background: #3A6200;
    color: white;
    border-radius: 0 20px 20px 0;
    font-weight: 600;
    border :1px solid #3A6200;
    padding: 5px 10px;
    transition: 0.5s;
    font-size: 15px;
  }
  .btn-green:hover{
    background:transparent;
    color: #3A6200;
    border-radius: 0 20px 20px 0;
    font-weight: 600;
    border :1px solid #3A6200;
    padding: 5px 10px;
    transition: 0.5s; 
    font-size: 15px;
  }
  .btn-black{
    background: black;
    color: white;
    border-radius: 0 20px 20px 0;
    font-weight: 600;
    border :1px solid black;
    padding: 5px 10px;
    transition: 0.5s;
    font-size: 15px;
  }
  .btn-black:hover{
    background:transparent;
    color: black;
    border-radius: 0 20px 20px 0;
    font-weight: 600;
    border :1px solid black;
    padding: 5px 10px;
    transition: 0.5s; 
    font-size: 15px;
  }
  .rateLeft{
    background:#e9eaf1 ;
    border-radius: 60px 0 0 60px;
  }
  .rateRight{
    background:#e9eaf1 ; 
    border-radius:  0 60px  60px 0;
  
  }
  .rateIcon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: white;
    margin: 0 5px;
  }
  .rateIcon > img{
    width: 20px;
  }
  .rateTitle{
    font-size: 10px;
  }
  .rateNumber{
    font-size: 20px; 
  }
  
 }

 @media (max-width: 576px) {
  .HomeSlider{
    height: 45vh;
  }
 }
.react-multiple-carousel__arrow--right{
  z-index: 1 !important;
}

.react-multiple-carousel__arrow--left{
  z-index: 1 !important;
}
.wave-effect {
  background: #FD5900;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 9999;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible; /* Allow the wave to overflow */
}

.wave-effect img {
  height: 35px;
  width: 35px;
}

.wave-effect::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(253, 89, 0, 0.5);
  z-index: -1;
  transform: scale(1);
  opacity: 0;
  transition: opacity 0.2s ease ;
}

.wave-effect:hover::before {
  animation: waveAnimation 1.5s infinite ease;
  opacity: 1;
}

@keyframes waveAnimation {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  25% {
    transform: scale(1.2);
    opacity: 0.6;
  }
  50% {
    transform: scale(1.4);
    opacity: 0.4;
  }
  75% {
    transform: scale(1.6);
    opacity: 0.2;
  }
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}
