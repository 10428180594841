@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); 
 
@import url('https://fonts.googleapis.com/css2?family=Anek+Gujarati:wght@100..800&display=swap');
 
 

html{
  scrollbar-width: none; 
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  scrollbar-width: none;  
  color: #1A2B71 !important;
}

.poppins{
  font-family: "Poppins", sans-serif !important; 
}
.anekGujarati{
  font-family: "Anek Gujarati", sans-serif !important;
}


p{
  padding: 0;
  margin: 0;
}
.tjustify{
  text-align: justify;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input{
  accent-color: #ec5808  !important;
}

.react-multiple-carousel__arrow--left{
  left: calc(0% + 1px) !important;
 
}
.react-multiple-carousel__arrow--right{
  right: calc(0% + 1px) !important;
 
}



::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.loader{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,0.6);
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  z-index: 99;
  
}