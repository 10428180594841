.calleryCart > div {
  border-radius: 20px 20px 0 0;
  border-bottom: 3px solid #1a2b71;
  overflow: hidden;
  transition: 0.5s;
}

.calleryCart:hover > div {
  border-radius: 20px 20px 0 0;
  border-bottom: 3px solid #fd5900;
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s;
}
.calleryCart .calleryImage {
  width: auto;
  border-radius: 20px 20px 0 0;
  height: 250px;
  aspect-ratio: 1/1;
}
.albums{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(0,0,0,0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
}
