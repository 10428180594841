.topSliderWindow {
  display: block;
}
.topSliderLaptop {
  display: none;
}
.DaynamicTopSlider {
  height: 70vh;
}

/* .table-fill{
	background: white;
	border-radius: 20px;
	border-collapse: collapse;
	height: 320px;
	margin: auto;
	max-width: 600px;
	padding: 5px;
	width: 100%;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
	animation: float 5s infinite;
} */
table th {
  color: #d5dde5 !important;
  background: #1a2b71 !important;
  border-right: 1px solid #1a2b71 !important;
  padding:5px !important;
  text-align: center !important;
  vertical-align: middle !important;
  border-top: none !important;
  min-width:fit-content !important;
}
 
th:last-child {
  border-top-right-radius: 0px;
  border-right: none;
}
th:first-child {
  border-top-left-radius: 20px;
  border-top: none;
  border-left: none;
}
td {
  min-width: fit-content !important;
  font-size: 16px !important;
  font-weight: 600; 
  padding: 5px !important;
} 
tr {
  /* border-left: 1px solid #1A2B71; */
  border-bottom: 1px solid #1a2b71;
  color: #1a2b71;
  font-weight: normal;
  text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
  text-align: center;
  
}
tr:hover td {
  background: #1a2b71;
  color: #ffffff !important;
  border-top: 1px solid #1a2b71;
  border-right: 1px solid #c1c3d1;
}

td {
  background: #ffffff;
  padding: 15px;
  text-align: left;
  vertical-align: middle;
  font-weight: 300;
  font-size: 18px;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
  border-right: 1px solid #1a2b71;
  text-align: center;
  color: #1a2b71 !important;

}

 



.daynamicMaltipalWay {
  position: relative;
  width: 100%;
  height: 350px;
  border-radius: 0 20px 20px 20px;
  overflow: hidden;
  transition: 1s;
  border: 2px solid #fd5900;
}
.daynamicMaltipalWayText {
  position: absolute;
  background-color: white;
  top: 85%;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: black;
  transition: 1s;
}
.daynamicMaltipalWayTextL {
  display: none;
}
.daynamicMaltipalWay:hover .daynamicMaltipalWayText {
  top: 15%;
  left: 0;
  text-align: start;
  transition: 1s;
}
.daynamicMaltipalWay:hover .daynamicMaltipalWayTextL {
  display: block;
}
.icon {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50px;
}
.icon > img {
  width: 50px;
}

.d_180 {
  transition: 1s;

  transform: rotateX("180deg") !important;
}
@media (max-width: 1450px) {
  .daynamicMaltipalWay {
    height: 330px;
  }
  .daynamicMaltipalWay:hover .daynamicMaltipalWayText {
    top: 5%;
    left: 0;
    text-align: start;
    transition: 1s;
  }
  .icon {
    width: 80px !important;
    height: 80px !important;
    border-radius: 50px;
  }
  .icon > img {
    width: 30px;
  }
  td {
    /* min-width: 200px !important; */
    font-size: 14px !important;
    font-weight: 600;

  } 
}

@media (max-width: 992px) {
  .topSliderWindow {
    display: none;
  }
  .topSliderLaptop {
    display: block;
  }
  td {
    min-width: 200px !important;
    font-size: 12px !important;
    font-weight: 600;

  } 
}
@media (max-width: 576px) {
  .DaynamicTopSlider {
    height: 45vh;
  }
  .icon {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50px;
  }
  .icon > img {
    width: 20px;
  }
  td {
    min-width: 200px !important;
    font-size: 12px !important;
    font-weight: 600;
  } 
}
