.mainNav {
  background-color: white;
  transition: 1s;
}
.navColor {
  background-color: #1a2b71;
  transition: 1s;
}
.header1 {
  border-radius: 15px;
  background-color: #fd5900;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px 15px;
  height: fit-content;
  color: white;
  border: none;
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;

}
.header1:hover {
  background-color: #d34e06;
}
.header2:hover {
  background-color: #1e8a12;
}
.header1Text {
  font-size: 13px;
}
.header2 {
  border-radius: 15px;
  background-color: #29a71a;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px 15px;
  height: fit-content;
  color: white;
  border: none;
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;

}

.header2Text {
  font-size: 13px;
}
.navbarMain {
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
  height: auto;
  transition: 300ms;
  padding: 0;
}
.navbarMain--hidden {
  transform: translateY(-175px);
  transition: 2000ms;
}
.windowNavbar {
  display: block;
}
.mobileNavbar {
  display: none;
}
.windowNavbar > div {
  position: relative;
  display: flex;
  gap: 30px;
  color: white;
  z-index: 2;
}

.NavbarSubMenuSection {
  background-color: #e9eaf1;
  color: black;
  position: absolute;
  width: 100%;
  left: 0%;
  top: 100%;
  min-height: 200px;
  z-index: 2;
}

@media (max-width: 990px) {
  .navbarMain {
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
    height: auto;
    transition: 300ms;
    padding: 0;
  }
  .navbarMain--hidden {
    transform: translateY(0px);
    transition: 2000ms;
  }
  .windowNavbar {
    display: none;
  }
  .mobileNavbar {
    display: block;
  }
  .header1 {
    border-radius: 10px;
    background-color: #fd5900;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 5px 15px;
    height: fit-content;
    color: white;
    border: none;
    font-size: 15px;
    text-decoration: none;
    cursor: pointer;
  }
  .header1Text {
    font-size: 10px;
  }
  .header2 {
    border-radius: 10px;
    background-color: #1e8a12;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 5px 15px;
    height: fit-content;
    color: white;
    border: none;
    font-size: 15px;
    text-decoration: none;
    cursor: pointer;

  }
  .header2Text {
    font-size: 10px;
  }
  .header1:hover {
    background-color: #d34e06;
  }
  .header2:hover {
    background-color: #1e8a12;
  }
}
